import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import SEO from '../components/seo';
import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import { updateService } from '../components/store'

const gradientVariants = {
	visible: { backgroundPositionY: 0 },
	hidden: { backgroundPositionY: '100vh' }
};

const imageVariants = {
	visible: {
		opacity: 0.5,
		scaleX: 1
	},
	hidden: {
		opacity: 0,
		scaleX: 0
	}
};

const textVariants = {
	visible: { opacity: 1 },
	hidden: { opacity: 0 }
}

const letterVariants = {
	visible: {
		opacity: 1,
		y: 0
	},
	hidden: {
		opacity: 0,
		y: -80
	}
}

const subtitleVariants = {
	visible: {
		opacity: 1,
		x: 0
	},
	hidden: {
		opacity: 0,
		x: 600
	}
}

const TitleBig = ({ title, subtitle, firstLoad, anim }) => (
	<h2>
		<div>
			{title.split('').map((c, i) => (
				<motion.span
					key={i}
					variants={letterVariants}
					initial="hidden"
					animate={anim ? 'visible' : 'hidden'}
					transition={{
						delay: 0.05 * i,
						type: 'spring'
					}}
					style={{ display: 'inline-block' }}
				>
					{c}
				</motion.span>
			))}
		</div>
		<span>
			<div>
				{subtitle.split('').map((c, i) => (
					<motion.span
						key={i}
						variants={letterVariants}
						initial="hidden"
						animate={anim ? 'visible' : 'hidden'}
						transition={{
							delay: 0.05 * i,
							type: 'spring'
						}}
						style={{ display: 'inline-block' }}
					>
						{c}
					</motion.span>
				))}
			</div>
		</span>
	</h2>
);

const Services = ({ current, dispatch }) => {
	const [ firstLoad, setFirstLoad ] = useState(true);

	const constraintsRef = useRef(null);
	const x = useMotionValue(0);
	const rotateY = useTransform(x, [-100, 0, 100], [90, 0, -90], {
		clamp: false
	});
	const btnRight = useTransform(x, [100, 0, -100], [0, 0, 1], {
		clamp: false
	});
	const btnLeft = useTransform(x, [-100, 0, 100], [0, 0, 1], {
		clamp: false
	});

	useEffect(() => {
		setFirstLoad(false);
	}, [setFirstLoad]);

	return (
		<section className="services">
			<SEO
				title="Services"
				description="Nos services de Consulting, Développement, Site web et de Webmarketing nous permettent de réaliser votre transformation digitale."
			/>

			<motion.div
				className="bar-1"
				initial="hidden"
				animate={current === 2 ? 'visible' : 'hidden'}
				variants={gradientVariants}
				transition={{
					delay: 0,
					type: 'spring'
				}}
			>
				<motion.div
					className="bar-image"
					initial="hidden"
					animate={current === 0 ? 'visible' : 'hidden'}
					variants={imageVariants}
					transition={{
						delay: 0,
						type: 'spring'
					}}
				/>
			</motion.div>
			<motion.div
				className="bar-2"
				initial="hidden"
				animate={current === 3 ? 'visible' : 'hidden'}
				variants={gradientVariants}
				transition={{
					delay: 0,
					type: 'spring'
				}}
			>
				<motion.div
					className="bar-image"
					initial="hidden"
					animate={current === 1 ? 'visible' : 'hidden'}
					variants={imageVariants}
					transition={{
						delay: 0,
						type: 'spring'
					}}
				/>
			</motion.div>
			<motion.div
				className="bar-3"
				initial="hidden"
				animate={current === 0 ? 'visible' : 'hidden'}
				variants={gradientVariants}
				transition={{
					delay: 0,
					type: 'spring'
				}}
			>
				<motion.div
					className="bar-image"
					initial="hidden"
					animate={current === 2 ? 'visible' : 'hidden'}
					variants={imageVariants}
					transition={{
						delay: 0,
						type: 'spring'
					}}
				/>
			</motion.div>
			<motion.div
				className="bar-4"
				initial="hidden"
				animate={current === 1 ? 'visible' : 'hidden'}
				variants={gradientVariants}
				transition={{
					delay: 0,
					type: 'spring'
				}}
			>
				<motion.div
					className="bar-image"
					initial="hidden"
					animate={current === 3 ? 'visible' : 'hidden'}
					variants={imageVariants}
					transition={{
						delay: 0,
						type: 'spring'
					}}
				/>
			</motion.div>

			<motion.div
				style={{
					display: current === 0 ? 'none' : 'block',
					position: 'absolute',
					top: '50%',
					left: 40,
					width: 48,
					height: 48,
					marginTop: -24,
					opacity: btnLeft,
					scale: btnLeft
				}}
			>
				<LeftCircleTwoTone twoToneColor="#90a8b0" style={{ fontSize: 48 }}/>
			</motion.div>

			<motion.div
				style={{
					display: current === 3 ? 'none' : 'block',
					position: 'absolute',
					top: '50%',
					right: 40,
					width: 48,
					height: 48,
					marginTop: -24,
					opacity: btnRight,
					scale: btnRight
				}}
			>
				<RightCircleTwoTone twoToneColor="#90a8b0" style={{ fontSize: 48 }}/>
			</motion.div>

			<motion.div
				style={{
					display: current === 0 ? 'none' : 'block',
					position: 'absolute',
					top: '50%',
					left: 40,
					width: 32,
					height: 32,
					marginTop: -16,
					opacity: 0.3
				}}
				animate={{ opacity: 0 }}
				transition={{
					yoyo: Infinity,
					duration: 3,
					ease: 'easeInOut'
				}}
			>
				<LeftCircleTwoTone twoToneColor="#90a8b0" style={{ fontSize: 32 }}/>
			</motion.div>

			<motion.div
				style={{
					display: current === 3 ? 'none' : 'block',
					position: 'absolute',
					top: '50%',
					right: 40,
					width: 32,
					height: 32,
					marginTop: -16,
					opacity: 0.3
				}}
				animate={{ opacity: 0 }}
				transition={{
					yoyo: Infinity,
					duration: 3,
					ease: 'easeInOut'
				}}
			>
				<RightCircleTwoTone twoToneColor="#90a8b0" style={{ fontSize: 32 }}/>
			</motion.div>

			<motion.div
				className="slide-services"
				drag="x"
				ref={constraintsRef}
				dragConstraints={constraintsRef}
				style={{ x, rotateY }}
				onPointerUp={() => {
					if (x.current <= -80 && current < 3)
						dispatch(updateService(current + 1));

					if (x.current >= 80 && current > 0)
						dispatch(updateService(current - 1));
				}}
			>
				<div className="text-content">
					<TitleBig title="INNOVATION" subtitle="Consulting" firstLoad={firstLoad} anim={current === 0} />

					<motion.div
						initial="hidden"
						animate={current === 0 ? 'visible' : 'hidden'}
						variants={textVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<p className="mobile-hidden">Les nouvelles technologies numériques sont essentielles afin de demeurer concurrentielles et améliorer l’expérience client, cependant le plus grand défi de ces technologies reste celui de maintenir un niveau de qualité constant des produits et des services offerts, tout en s’adaptant à celles-ci.</p>
						<p>Notre service de consulting a été pensé pour vous aider à améliorer ou créer une architecture logiciel, mettre en place une stratégie numérique axée sur la technologie et améliorer vos opérations actuelles, tout en optimisant votre portefeuille de logiciels existants. Nous vous aidons ainsi à réaliser votre transformation ou évolution numérique en travaillant avec vous à la mise en place d'une planification réaliste et méticuleuse, suivie par une exécution professionnelle basée sur la stratégie prédéfinie lors du consulting.</p>
					</motion.div>

					<div />

					<motion.div
						className="subtitle"
						initial="hidden"
						animate={current === 0 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<div className="num">
							01.
						</div>
						<span>
							<strong>CONNECTEZ</strong>
							<br />
							VOS
							<br />
							IDÉES
						</span>
					</motion.div>
					<motion.div
						className="subtitle-mobile"
						initial="hidden"
						animate={current === 0 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<strong>01. CONNECTEZ</strong> VOS IDÉES
					</motion.div>
				</div>

				<div className="text-content">
					<TitleBig title="SOFTWARE" subtitle="Développement" firstLoad={firstLoad} anim={current === 1} />

					<motion.div
						initial="hidden"
						animate={current === 1 ? 'visible' : 'hidden'}
						variants={textVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<p>Nous créons des logiciels personnalisés grâce aux dernières technologies vous permettant de créer, améliorer et moderniser le portfolio de logiciels de votre entreprise. Notre équipe locale dédiée basera son travail sur une analyse afin de créer pour vous une solution sur mesure basée sur vos besoins réels.</p>
						<p className="mobile-hidden">De l'application d'entreprise permettant la numérisation du workflow à l'augmentation de la productivité des employés, en passant par les applications mobiles spécifiques au secteur de la vente pour gérer les stocks, nous sommes spécialisés dans les industries locales et utilisons les dernières technologies pour relier toutes les différentes applications utilisées par une entreprise en un ensemble d’outils cohérents interrelié.</p>
					</motion.div>

					<div />

					<motion.div
						className="subtitle"
						initial="hidden"
						animate={current === 1 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<div className="num">
							02.
						</div>
						<span>
							<strong>DIGITALISEZ</strong>
							<br />
							VOTRE
							<br />
							PROJET
						</span>
					</motion.div>
					<motion.div
						className="subtitle-mobile"
						initial="hidden"
						animate={current === 1 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<strong>02. DIGITALISEZ</strong> VOTRE PROJET
					</motion.div>
				</div>

				<div className="text-content">
					<TitleBig key="in-0" title="ENTREPRISE" subtitle="Site Web" firstLoad={firstLoad} anim={current === 2} />

					<motion.div
						initial="hidden"
						animate={current === 2 ? 'visible' : 'hidden'}
						variants={textVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<p>De la conception, au développement, en passant par l'optimisation pour les moteurs de recherche, nous couvrons l'entièreté du spectre de création d'un site web. En utilisant le web design et en travaillant l’interface utilisateur, nous guidons les visiteurs directement vers l'information dont ils ont besoin et faisons de leurs visites une expérience mémorable pour les convertir en clients. Les technologies que nous utilisons sont sécurisées et correspondent aux dernières normes de l'industrie, le développement est réalisé entièrement en interne.</p>
						<p className="mobile-hidden">Nous sommes très fiers de notre gestion et de notre compréhension du référencement web qui, jumelées à la conception et aux structures de sites web que nous créons, renforcent la présence de nos clients en ligne.</p>
					</motion.div>

					<div />

					<motion.div
						className="subtitle"
						initial="hidden"
						animate={current === 2 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<div className="num">
							03.
						</div>
						<span>
							<strong>ILLUSTREZ</strong>
							<br />
							VOTRE
							<br />
							ENTREPRISE
						</span>
					</motion.div>
					<motion.div
						className="subtitle-mobile"
						initial="hidden"
						animate={current === 2 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<strong>03. ILLUSTREZ</strong> VOTRE ENTREPRISE
					</motion.div>
				</div>

				<div className="text-content">
					<TitleBig key="in-0" title="PRODUITS" subtitle="Marketing" firstLoad={firstLoad} anim={current === 3} />

					<motion.div
						initial="hidden"
						animate={current === 3 ? 'visible' : 'hidden'}
						variants={textVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<p>Notre expertise dans le domaine du référencement web mêlant une panoplie d’outils tels que Facebook, Instagram et Google Ads nous permet d’atteindre des objectifs probants basés sur des indicateurs clés de performances. Notre but est d'ancrer la présence de nos client en ligne tout en engageant leurs audience à devenir des futures clients.</p>
						<p className="mobile-hidden">Nous vous aidons à établir votre présence sur les réseaux sociaux en créant vos pages et en les présentant à votre public au bon moment avec des campagnes publicitaires payantes sur les réseaux sociaux, et les moteurs de recherche. Une fois votre site web bien référencé et votre présence établie sur les réseaux sociaux, nous proposons des services d’analyse et d’optimisation afin de renforcer votre présence en ligne.</p>
					</motion.div>

					<div />

					<motion.div
						className="subtitle"
						initial="hidden"
						animate={current === 3 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<div className="num">
							04.
						</div>
						<span>
							<strong>COMMUNIQUEZ</strong>
							<br />
							VOS
							<br />
							PRODUITS
						</span>
					</motion.div>
					<motion.div
						className="subtitle-mobile"
						initial="hidden"
						animate={current === 3 ? 'visible' : 'hidden'}
						variants={subtitleVariants}
						transition={{
							delay: 0,
							type: 'spring'
						}}
					>
						<strong>04. COMMUNIQUEZ</strong> VOS PRODUITS
					</motion.div>
				</div>
			</motion.div>
		</section>
	);
}

export default connect(
	state => ({
		current: state.service
	})
)(Services);
